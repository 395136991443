import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Sidebar from "./components/sidebar/sidebar";
import Dashboard from "./components/pages/dashboard/dashboard";
import Train from "./components/pages/train/train";
import DocumentHub from "./components/pages/train/documenthub";
import Profile from "./components/pages/profile/profile";
import { ProtectedRoute } from "./components/protectedRoute";
import { Login } from "./auth/login";
import "./App.css";

function App() {
  return (
    <Router>
      <div className="App">
        <Sidebar className="sidebar" />
        <div className="main-content">
          <div className="cevian-logo">
            <img src="/cevian-logo.png" alt="Ask Cevian" />
          </div>
          <Routes>
            <Route
              path="/"
              element={
                <Login>
                  <ProtectedRoute>
                    <Dashboard />
                  </ProtectedRoute>
                </Login>
              }
            />
            <Route
              path="/train"
              element={
                <Login>
                  <ProtectedRoute>
                    <Train />
                  </ProtectedRoute>
                </Login>
              }
            />
            <Route
              path="/documenthub"
              element={
                <Login>
                  <ProtectedRoute>
                    <DocumentHub />
                  </ProtectedRoute>
                </Login>
              }
            />
            <Route
              path="/profile"
              element={
                <Login>
                  <ProtectedRoute>
                    <Profile />
                  </ProtectedRoute>
                </Login>
              }
            />
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;

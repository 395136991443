import React from "react";
import { useMsal } from "@azure/msal-react";

export const LogoutButton = () => {
  const { instance } = useMsal();

  const handleLogout = () => {
    instance.logoutPopup(); // Or use logoutPopup() if preferred
  };

  return (
    <button onClick={handleLogout} className="logout">
      <span className="material-symbols-outlined">logout</span>
      <span>Logout</span>
    </button>
  );
};

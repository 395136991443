import React, { useEffect, useState } from 'react';
import './profile.css';
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../../authConfig";
import { LogoutButton } from "../../../auth/logout";

function Profile() {
  const { instance, accounts } = useMsal();
  const [userData, setUserData] = useState({ name: "", email: "" });

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await instance.acquireTokenSilent({
          account: accounts[0],
          ...loginRequest,
        });

        const accessToken = response.accessToken;

        // Call Microsoft Graph API to get user profile information
        const graphResponse = await fetch("https://graph.microsoft.com/v1.0/me", {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        if (graphResponse.ok) {
          const userProfile = await graphResponse.json();
          setUserData({
            name: userProfile.displayName,
            email: userProfile.mail || userProfile.userPrincipalName,
          });
        } else {
          console.error("Failed to fetch user data from Graph API");
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchUserData();
  }, [instance, accounts]);

  return (
    <div className="profile">

        <h1>Profile</h1>

        <button className="name">
            <span>Name:</span>
            <span>{userData.name || "Fetching..."}</span>
        </button>
        <button className="email-id">
            <span>Email:</span>
            <span>{userData.email || "Fetching..."}</span>
        </button>

        <div className="logout">
            <LogoutButton />
        </div>

    </div>
  );
}

export default Profile;
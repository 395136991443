import React, { useEffect } from "react";
import { MsalAuthenticationTemplate, useMsal } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { loginRequest } from "../authConfig";

export const Login = ({ children }) => {
  const { accounts } = useMsal();

  useEffect(() => {
    console.log("Logged in accounts:", accounts);
  }, [accounts]);

  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={loginRequest}
    >
      {children}
    </MsalAuthenticationTemplate>
  );
};

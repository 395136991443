export const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_CLIENT_ID,
    authority: process.env.REACT_APP_AUTHORITY,
    redirectUri: process.env.REACT_APP_REDIRECT_URI // Replace with your app's redirect URI
  },
  cache: {
      cacheLocation: "localStorage", // You can use sessionStorage or localStorage
      storeAuthStateInCookie: true, // Set to true for IE11 or Edge
    },
};
 
export const loginRequest = {
  scopes: ["User.Read"], // Define the scopes your app needs
};

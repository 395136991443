import React, { useEffect } from "react";
import { Navigate } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../authConfig";

export const ProtectedRoute = ({ children }) => {
  const { accounts, instance } = useMsal();

  useEffect(() => {
    if (accounts.length > 0) {
      instance
        .acquireTokenSilent({
          account: accounts[0],
          ...loginRequest,
        })
        .then((response) => {
          console.log("Access Token:", response.accessToken);
        })
        .catch((error) => {
          console.error("Failed to acquire token silently. Logging out.", error);
          instance.logoutRedirect(); // Redirect to login on failure
        });
    }
  }, [accounts, instance]);

  return accounts.length > 0 ? children : <Navigate to="/" />;
};

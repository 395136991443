import React, { useState, useEffect, useRef } from 'react';
import { useMsal } from '@azure/msal-react';
import './dashboard.css';

function Dashboard() {
    const [chatMessages, setChatMessages] = useState([]); // Combined messages for user and model
    const [isStreaming, setIsStreaming] = useState(false);
    const [userProfilePic, setUserProfilePic] = useState('');
    const { instance } = useMsal();

    useEffect(() => {
        fetchUserProfilePicture();
    }, []);

    const fetchUserProfilePicture = async () => {
        try {
            const account = instance.getActiveAccount();
            if (!account) return;

            const tokenResponse = await instance.acquireTokenSilent({
                scopes: ['User.Read'],
                account: account
            });

            // Fetch profile photo using Microsoft Graph API
            const response = await fetch('https://graph.microsoft.com/v1.0/me/photo/$value', {
                headers: {
                    Authorization: `Bearer ${tokenResponse.accessToken}`
                }
            });

            if (response.ok) {
                const blob = await response.blob();
                const imageUrl = URL.createObjectURL(blob);
                setUserProfilePic(imageUrl);
            } else {
                console.error('Failed to fetch profile picture:', response.status);
            }
        } catch (error) {
            console.error('Error fetching profile picture:', error);
        }
    };

    // Function to fetch and stream model messages from the backend
    const fetchStream = async (userMessageIndex) => {
        try {
            console.log('Stream started'); // Debug log
            setIsStreaming(true);
            const response = await fetch('http://localhost:8000/stream');
            const reader = response.body.getReader();
            const decoder = new TextDecoder("utf-8");

            let done = false;
            while (!done) {
                const { value, done: streamDone } = await reader.read();
                done = streamDone;
                const chunk = decoder.decode(value, { stream: true });

                if (chunk) {
                    setChatMessages((prev) =>
                        prev.map((message, index) =>
                            index === userMessageIndex
                                ? { ...message, modelResponse: (message.modelResponse || '') + chunk }
                                : message
                        )
                    );
                }
            }
        } catch (error) {
            console.error('Error fetching stream:', error);
        } finally {
            console.log('Stream ended'); // Debug log
            setIsStreaming(false);
        }
    };

    const handleNewUserMessage = (userMessage) => {
        setChatMessages((prev) => [
            ...prev,
            { type: 'user', message: userMessage, modelResponse: '' }
        ]);

        // Trigger fetching of model response
        fetchStream(chatMessages.length);
    };

    return (
        <div className="dashboard">
            {chatMessages.length === 0 ? (
                <div className="ask-cevian">
                    <img src="/AskCevian.png" alt="Ask Cevian" />
                </div>
            ) : (
                <div className="chat-section">
                    {chatMessages.map((msg, index) => (
                        <div key={index}>
                            <div className="chat-user">
                                <div className="user-image">
                                    {userProfilePic ? (
                                        <img 
                                            src={userProfilePic} 
                                            alt="User Profile" 
                                            className="profile-image"
                                        />
                                    ) : (
                                        <div className="profile-image-placeholder" />
                                    )}
                                </div>
                                
                                <div className="user-message-text">
                                    {msg.type === 'user' && <span>{msg.message}</span>}
                                </div>
                            </div>
                            <div className="chat-model">
                                <div className="loaders">
                                    {isStreaming && index === chatMessages.length - 1 ? (
                                        <div className="stream-loader">
                                            <img src="/mag-glass-icon.png" alt="" />
                                        </div>
                                    ): (<div className="stream-loader-still">
                                        <img src="/mag-glass-icon.png" alt="" />
                                    </div>)}
                                </div>
                                {msg.modelResponse && <span>{msg.modelResponse}</span>}
                            </div>
                        </div>
                    ))}
                    <div className="scroll-spacer"></div>
                </div>
            )}

            <div className="footer">
                <PromptBox onSendMessage={handleNewUserMessage} />
            </div>
        </div>
    );
}  

export default Dashboard;

// -------------------- PromptBox Component --------------------

function PromptBox({ onSendMessage }) {
    const inputRef = useRef(null); // Reference to the input field

    useEffect(() => {
        const textarea = inputRef.current;

        const handleInput = () => {
            textarea.style.height = 'auto';
            textarea.style.height = `${Math.min(textarea.scrollHeight, 200)}px`;
        };

        textarea.addEventListener('input', handleInput);
        return () => {
            textarea.removeEventListener('input', handleInput);
        };
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();
        const inputField = inputRef.current; // Get the textarea element

        if (!inputField) return; // Safety check if input is not found

        const message = inputField.value.trim();
        if (!message) return; // Prevent empty messages

        onSendMessage(message); // Send message to parent component
        inputField.value = '';
        inputField.style.height = 'auto';
    };

    return (
        <div className="promptbox">
            <form onSubmit={handleSubmit} className="input-form">
                <textarea
                    ref={inputRef} // Attach ref to textarea
                    placeholder="Message Cevian"
                    className="input-field"
                    onKeyDown={(e) => {
                        if (e.key === 'Enter' && !e.shiftKey && e.target.value.trim()) {
                            e.preventDefault();
                            handleSubmit(e);
                        }
                    }}
                />
                <button type="submit" className="input-button">
                    <img src="/send-icon.png" alt="Send" />
                </button>
            </form>
        </div>
    );
}

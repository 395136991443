// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.profile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 60%;
    gap: 20px;
    padding: 10px;
    position: absolute;
    top: 100px;
}

.profile h1 {
    padding: 15px;
    font-size: 40px;
    color: #7B7C8E;
}

.profile button {
    display: flex;
    justify-content: start;
    align-items: center;
    width: 100%;
    height: 70px;
    padding: 15px;
    background-color: #F3F3F4;
    border-radius: 10px;
    box-shadow: -3px 3px 5px rgba(0, 0, 0, 0.25);
    gap: 15px;
    cursor: pointer;
    overflow: hidden;
    border: none; /* Added to remove default button border */
    transition: background-color 0.3s ease, box-shadow 0.3s ease; /* Added transition */
}

.profile button:hover {
    background-color: #3F2AA8; /* Slightly darker background on hover */
    box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.35); /* Enhanced shadow on hover */
}

.profile button:hover span{
    color: #F3F3F4; /* Changed icon color on hover */
}



.profile button span {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    color: #7B7C8E;
}

.logout span.material-symbols-outlined {
    font-size: 35px;
}
`, "",{"version":3,"sources":["webpack://./src/components/pages/profile/profile.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,UAAU;IACV,SAAS;IACT,aAAa;IACb,kBAAkB;IAClB,UAAU;AACd;;AAEA;IACI,aAAa;IACb,eAAe;IACf,cAAc;AAClB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,WAAW;IACX,YAAY;IACZ,aAAa;IACb,yBAAyB;IACzB,mBAAmB;IACnB,4CAA4C;IAC5C,SAAS;IACT,eAAe;IACf,gBAAgB;IAChB,YAAY,EAAE,0CAA0C;IACxD,4DAA4D,EAAE,qBAAqB;AACvF;;AAEA;IACI,yBAAyB,EAAE,wCAAwC;IACnE,2CAA2C,EAAE,6BAA6B;AAC9E;;AAEA;IACI,cAAc,EAAE,gCAAgC;AACpD;;;;AAIA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,eAAe;IACf,cAAc;AAClB;;AAEA;IACI,eAAe;AACnB","sourcesContent":[".profile {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    width: 60%;\n    gap: 20px;\n    padding: 10px;\n    position: absolute;\n    top: 100px;\n}\n\n.profile h1 {\n    padding: 15px;\n    font-size: 40px;\n    color: #7B7C8E;\n}\n\n.profile button {\n    display: flex;\n    justify-content: start;\n    align-items: center;\n    width: 100%;\n    height: 70px;\n    padding: 15px;\n    background-color: #F3F3F4;\n    border-radius: 10px;\n    box-shadow: -3px 3px 5px rgba(0, 0, 0, 0.25);\n    gap: 15px;\n    cursor: pointer;\n    overflow: hidden;\n    border: none; /* Added to remove default button border */\n    transition: background-color 0.3s ease, box-shadow 0.3s ease; /* Added transition */\n}\n\n.profile button:hover {\n    background-color: #3F2AA8; /* Slightly darker background on hover */\n    box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.35); /* Enhanced shadow on hover */\n}\n\n.profile button:hover span{\n    color: #F3F3F4; /* Changed icon color on hover */\n}\n\n\n\n.profile button span {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    font-size: 20px;\n    color: #7B7C8E;\n}\n\n.logout span.material-symbols-outlined {\n    font-size: 35px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
